import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import ProfileOne from "../../assets/images/no-image-2.png";
import { hostConfig } from "../../config";
import { gettoppostData } from "../../redux/action/reviewAction";
import { SearchTution } from "../../redux/action/searchAction";

const ReviewSection = styled.div`
  padding: 40px 0;
  background: #f8f9fa;
`;

const ReviewTitle = styled.h2`
  font-size: 28px;
  font-weight: 700;
  color: #012834;
  margin-bottom: 30px;
  position: relative;
  padding-left: 15px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #940e34;
    border-radius: 2px;
  }
`;

const CardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 0 15px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

const PostCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  height: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.12);
  }
`;

const PostImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 66.67%; // 3:2 aspect ratio
  background: #ffe4d9;
  overflow: hidden;
`;

const PostImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${PostCard}:hover & {
    transform: scale(1.05);
  }
`;

const PostContent = styled.div`
  padding: 20px;
`;

const PostTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #012834;
  margin-bottom: 10px;
  line-height: 1.3;
`;

const PostSubtitle = styled.div`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
  line-height: 1.5;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const PostDate = styled.div`
  font-size: 13px;
  color: #888;
  margin-top: auto;
`;

const DiscountBadge = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #940e34;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  box-shadow: 0 2px 8px rgba(148, 14, 52, 0.3);
`;

const ReviewSectionCard = () => {
  const [postData, setPostData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllPosts = async () => {
    try {
      setIsLoading(true);
      const response = await dispatch(gettoppostData());
      if (response?.records) {
        // Create array of 4 items using the single record
        const repeatedData = Array(4).fill(response.records[0]);
        setPostData(repeatedData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  const handleCardClick = (item) => {
    const searchParams = {
      area: "",
      batch_id: "",
      city: "",
      level: "",
      subject: "",
      tuition_name: item?.tuition_name,
    };

    dispatch(SearchTution(searchParams));

    const queryString = new URLSearchParams({
      LOCATION_NAME: "",
      SELECT_AREA: "",
      SELECT_LEVEL: "",
      DAYS_OF_WEEK: "0",
      TIME: "",
      SUBJECT_NAME: "",
      CITY_ID: "",
      TUITION_NAME: item?.tuition_name || "",
    }).toString();

    navigate(`/tuition-list?${queryString}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (isLoading) {
    return null; // Or a loading spinner if needed
  }

  return (
    <ReviewSection>
      <Container>
        <ReviewTitle>Recent Posts From Tuitions</ReviewTitle>
        <CardsGrid>
          {postData.map((item, index) => (
            <PostCard key={index} onClick={() => handleCardClick(item)}>
              <PostImageWrapper>
                <PostImage
                  src={`${hostConfig.S3_BUCKET}${item.image_file_name}`}
                  alt={item.title}
                  onError={(e) => {
                    e.target.src = ProfileOne;
                    e.target.onerror = null;
                  }}
                />
                {item.title.toLowerCase().includes('discount') && (
                  <DiscountBadge>
                    {item.title.match(/\d+/)?.[0]}% OFF
                  </DiscountBadge>
                )}
              </PostImageWrapper>
              <PostContent>
                <PostTitle>{item.title}</PostTitle>
                <PostSubtitle>{item.sub_title}</PostSubtitle>
                <PostDate>{formatDate(item.validated_date)}</PostDate>
              </PostContent>
            </PostCard>
          ))}
        </CardsGrid>
      </Container>
    </ReviewSection>
  );
};

export default ReviewSectionCard;