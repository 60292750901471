import React from "react";
import styled from "styled-components";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import brandStoryBoy from '../../assets/images/brand_story_boy.png';
import brandStoryGirl from '../../assets/images/brand_story_girl.png';
import Sumit from '../../assets/images/Sumit.png';
import Ejaz from '../../assets/images/Ejaz.png';


// Styled Components
const SectionWrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const SectionTitle = styled.h2`
  color: #141414;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background-color: #8B1538;
  }
`;

const BannerWrapper = styled.div`
  background-color: #8B1538;
  color: #ffffff;
  padding: 6rem 2rem;
  text-align: center;
  h1 {
    font-size: 3.5rem;
    margin-bottom: 1.5rem;
  }

  p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }
`;

const CompanyWrapper = styled(SectionWrapper)`
  padding: 4rem 2rem;
  background-color: #f9f9f9;

  .story {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @media (max-width: 768px) {
      gap: 2rem;
    }
  }

  .story-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 1.5rem; 

    &.reverse {
      grid-template-columns: 1fr 1fr;
      direction: rtl; 
      text-align: right;

      .text-content {
        direction: ltr;
        text-align: left;
      }
    }

    &.text-only {
      grid-template-columns: 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr; 
      direction: ltr; 
      .text-content {
        order: -1;
      }

      .image-content {
        order: 1; 
      }

      &.reverse {
        grid-template-columns: 1fr;
        text-align: left;
      }
    }
  }

  .text-content {
    p {
      margin-bottom: 1.5rem;
      font-size: 1.1rem;
      line-height: 1.7;
      color: #4a5568;

      em {
        font-style: italic;
        color: #8b1538;
      }

      strong {
        color: #8b1538;
      }
    }
  }

  .image-content {
    img {
      max-width: 90%; 
      border-radius: 10px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;


const VisionMissionWrapper = styled(SectionWrapper)`
  background-color: #f8f9fa;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
      background-color: #8b1538;
      color: white;

      h3 {
        color: white; 
      }

      p {
        color: white; 
      }
    }

    h3 {
      color: #8b1538;
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
    }

    p {
      line-height: 1.7;
      color: #4a5568;
    }
  }
`;

const TeamWrapper = styled(SectionWrapper)`
  .team-intro {
    text-align: center;
    max-width: 800px;
    margin: 0 auto 4rem;
  }

  .team-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;

    @media (max-width: 968px) {
      grid-template-columns: 1fr;
    }
  }

  .team-member {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    }

    .image-container {
      width: 200px;
      height: 200px;
      margin: 0 auto 2rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    h3 {
      color: #8b1538;
      font-size: 1.8rem;
      margin-bottom: 1rem;
      text-align: center;
    }

    p {
      color: #4a5568;
      line-height: 1.7;
    }
  }
`;

// Components
const Banner: React.FC = () => {
  return (
    <BannerWrapper>
      <h1>About Tuition Mitra</h1>
      <p>Partner in your career transformation!</p>
    </BannerWrapper>
  );
};

const ValuesWrapperCompact = styled.section`
  padding: 3rem 1.5rem;
  background-color: #f8f9fa;
  border-radius: 10px;

  .intro-text {
    font-size: 1rem;
    color: #555;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .values-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }

  .value-chip {
    display: flex;
    align-items: center;
    gap: 1rem;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    }

    .value-icon {
      font-size: 1.5rem;
      color: #8b1538;
    }

    .value-content {
      h3 {
        margin: 0;
        font-size: 1.2rem;
        color: #222;
      }
      p {
        margin: 0.3rem 0 0;
        color: #555;
        font-size: 0.9rem;
      }
    }
  }
`;


const OurBrandStory: React.FC = () => {
  return (
    <CompanyWrapper>
      <SectionTitle>Our Brand Story</SectionTitle>
      <div className="story">
        <div className="story-section">
          <div className="text-content">
            <p>
              We met a student from the nearby village of Patna. At that time, he had passed his matriculation. Like a
              normal student, he also had ambitions to study in premier colleges, make it big, travel, and have a big
              house. He was willing to fulfill the dream through perseverance, hard work, and more. The first step was
              to move to a nearby town where he could access teachers, tuition, and students who could help in acquiring
              the right knowledge. However, he was in a dilemma – <em>which teacher or tuition is best suited for him?</em>
            </p>
          </div>
          <div className="image-content">
            <img src={brandStoryBoy} alt="Ambitious student boy" />
          </div>
        </div>
        <div className="story-section reverse">
          <div className="image-content">
            <img src={brandStoryGirl} alt="Ambitious student girl" />
          </div>
          <div className="text-content">
            <p>
              Incidentally, we met a girl student from another village. She was preparing for her HSC exams. She shared
              the same aspiration story. She was also skeptical about coming to town and staying in an unknown
              environment. She felt that her family might not be comfortable with her staying in a town until assured of
              her well-being and security.
            </p>
          </div>
        </div>
        <div className="story-section text-only">
          <div className="text-content">
            <p>
              As we began to talk with more students, both from villages and towns, most had similar tales to say about
              their problems, primarily in finding the right tuition. We then met teachers and tuition owners. They also
              had stories to share, along with problems they face in managing student data, seamless communication with
              students, and monitoring fees, to name a few.
            </p>
            <p>
              These stories motivated us to create something that could help such students as well as the teachers and
              tuition owners. It took about three years for us to research, define the problem, ideate, and then develop
              this application – <strong>Tuition Mitra</strong>. As the name suggests, Tuition Mitra is the friend of students, teachers, tuitions, and coaching institutes. The students can rely on Tuition Mitra to find and enrol in to the most suitable tuition, find accommodation and study materials, and more. The teachers, tuitions, and coaching institutes can rely on Tuition Mitra to manage with ease the students, batches, study materials, and more on a trusted platform where the data is secured.
            </p>
          </div>
        </div>
      </div>
    </CompanyWrapper>
  );
};


const VisionMission: React.FC = () => {
  return (
    <VisionMissionWrapper>
      <SectionTitle>Vision & Mission</SectionTitle>
      <div className="grid">
        <div className="card">
          <h3>Vision</h3>
          <p>
            Empowering students and families by providing seamless access to the best tuition opportunities,
            ensuring every learner can achieve their educational dreams without financial barriers.
          </p>
        </div>
        <div className="card">
          <h3>Mission</h3>
          <p>
            Our mission is to revolutionize the educational experience by seamlessly connecting students with
            exceptional tuition services, suitable accommodation, and nutritious meals. We empower educators
            and tuition owners with robust tools to manage student data and facilitate effective communication,
            fostering a supportive and efficient learning environment for all.
          </p>
        </div>
      </div>
    </VisionMissionWrapper>
  );
};

const OurValues: React.FC = () => {
  const values = [
    {
      title: "Accessibility",
      description:
        "Ensuring every student can easily find quality tuition, comfortable accommodation, and nutritious meals.",
      icon: "fa-solid fa-universal-access",
    },
    {
      title: "Innovation",
      description:
        "Continuously improving our platform to provide the best tools for students, educators, and tuition owners.",
      icon: "fa-solid fa-lightbulb",
    },
    {
      title: "Transparency",
      description:
        "Maintaining clear and honest communication with all our users.",
      icon: "fa-solid fa-eye",
    },
    {
      title: "Collaboration",
      description:
        "Fostering a community where students, educators, and tuition owners can work together seamlessly.",
      icon: "fa-solid fa-handshake",
    },
    {
      title: "Excellence",
      description:
        "Striving for the highest standards in everything we do to ensure a thriving learning environment for all.",
      icon: "fa-solid fa-award",
    },
  ];

  return (
    <ValuesWrapperCompact>
      <SectionTitle>Our Values</SectionTitle>
      <p className="intro-text">
        "At our core, we believe in creating an inclusive and supportive
        educational ecosystem. Our values are centered around:"
      </p>
      <div className="values-grid">
        {values.map((value, index) => (
          <div className="value-chip" key={index}>
            <i className={`value-icon ${value.icon}`}></i>
            <div className="value-content">
              <h3>{value.title}</h3>
              <p>{value.description}</p>
            </div>
          </div>
        ))}
      </div>
    </ValuesWrapperCompact>
  );
};


const MeetTheTeam: React.FC = () => {
  return (
    <TeamWrapper>
      <SectionTitle>Meet the Team</SectionTitle>
      <div className="team-intro">
        <p>
          Tuition Mitra is the proprietary software of EthosITy solutions. We are a technology company run by
          experienced IT professionals. The average years of experience of company executives is more than 15 years.
        </p>
      </div>
      <div className="team-grid">
        <div className="team-member">
          <div className="image-container">
            <img src={Sumit} alt="Sumit Singh" />
          </div>
          <h3>Sumit Singh</h3>
          <p>
            A self-motivated and collaborative product manager with strengths in Technology, Product Management,
            Software Development, and Product Strategy. Sumit has worked for multiple tech organizations for
            nearly 10 years and gained strong technology and business skills in multiple industries including
            healthcare and financial services. He pursued his MBA degree from University of Pittsburgh, USA and
            holds engineering degree in Electronics and Communication.
          </p>
        </div>
        <div className="team-member">
          <div className="image-container">
            <img src={Ejaz} alt="Ejaz Sayed" />
          </div>
          <h3>Ejaz Sayed</h3>
          <p>
            Ejaz has over 15 years of experience in the IT industry and has worked on content and design projects.
            He holds a master's degree in User Experience Design from London and has also done an MBA in finance.
            He is passionate about socio-economic designs. He is an avid traveler and likes to escape to mountains
            and countryside whenever off-work.
          </p>
        </div>
      </div>
    </TeamWrapper>
  );
};

const AboutUs: Function = () => {
  return (
    <>
      <Header />
      <Banner />
      <OurBrandStory />
      <VisionMission />
      <OurValues />
      <MeetTheTeam />
      <Footer />
    </>
  );
};

export default AboutUs;
