import styled from "styled-components";

export const FooterContainer = styled.footer`
  position: relative;
  background: #f7f7f7;
  padding: 25px 0 0; 
`;

export const FooterSlant = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 120px; 
  background: #940e34;
  clip-path: polygon(0 40%, 100% 0, 100% 100%, 0% 100%);
`;

export const FooterContent = styled.div`
  position: relative;
  z-index: 2;
  padding: 0 0 60px; 
`;

export const FooterSection = styled.div`
  margin-bottom: 10px; 
`;

export const SectionTitle = styled.h3`
  color: #012834;
  font-size: 16px; 
  font-weight: 700;
  margin-bottom: 12px; 
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 25px;
    height: 2px;
    background: #940e34;
  }
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; 

  a {
    color: #012834;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    transition: color 0.3s ease;
    line-height: 1.2; 

    &:hover {
      color: #940e34;
    }
  }
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; 
`;

export const ContactItem = styled.div`
  color: #012834;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  display: flex;
  gap: 8px;
  align-items: baseline;

  strong {
    font-weight: 600;
    white-space: nowrap;
  }

  a {
    color: #012834;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #940e34;
    }
  }
`;

export const FooterBottom = styled.div`
  position: relative;
  z-index: 2;
  padding: 15px 0; 
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const Copyright = styled.p`
  color: #ffffff;
  margin: 0;
  font-size: 14px;
`;

export const SocialLinks = styled.div`
  display: flex;
  gap: 12px; 

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px; 
    height: 28px;
    background: #ffffff;
    border-radius: 50%;
    transition: all 0.3s ease;

    svg {
      width: 14px;
      height: 14px;
      fill: #940e34;
    }

    &:hover {
      transform: translateY(-2px);
      background: #f7f7f7;
    }
  }
`;