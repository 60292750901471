import { SelectProps } from "antd";
import { useEffect, useState } from "react";
import { Button, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import TuitionMitraLogo from "../../assets/images/TuitionMitraLogo.svg";
import UserImage from "../../assets/images/user-placeholder-small.png";
import {
  AfterLogin,
  HeaderMain,
  NavLogo,
  RightHeader,
  UserCategory,
  UserImageWrap,
  UserInfo,
  UserName,
} from "./headerStyle";
// import { loginUserAction } from "../../redux/action/login/login-slice";
// import { teacherLoginAction } from "../../redux/action/teacherLogin/teacherLogin-slice";
import { hostConfig } from "../../config";
import BookADemoSearchPopup from "../BookADemoSearchPopup";
import LoginModal from "../LoginModal";


const Header = (): React.JSX.Element => {
  const navigate: any = useNavigate();
  const location = window.location.pathname;

  const [show, setShow] = useState(false);
  const [items, setItems] = useState<any>(null);
  const [showLoginRental, setShowLoginRental] = useState<boolean>(false);

  const goToLoginPage = () => {
    if (location === "/services") {
      setShowLoginRental(true);
      return;
    }
    navigate("/auth/login");
  };

  const goToRegisterPage = () => {
    if (location === "/services") return;
    navigate("/auth/register");
  };

  const email = localStorage.getItem("token");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options: SelectProps["options"] = [];

  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }

  const navigateToFindaTution = () => {
    navigate("/");
  };

  const selectLoginInfo = useSelector((state: any) => state?.login);
  const teacherLoginInfo = useSelector((state: any) => state?.teacherLogin);

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("loggedUsers") || "{}");
    if (items) {
      setItems(items);
    }
  }, []);

  const getUserType = () => {
    return items?.is_student ? 'Student' : 'Teacher';
  };

  return (
    <>
      <HeaderMain>
        <Navbar collapseOnSelect expand="lg" className={items?.is_active ? 'user-active' : ""}>
          <Link to="/" style={{ position: "relative", top: -5 }}>
            <NavLogo src={TuitionMitraLogo} alt="Tuition Mitra" />
          </Link>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{ whiteSpace: "nowrap" }}
          >
            <Nav activeKey={location}>
              <Nav.Item>
                <Link
                  to="/"
                  onClick={navigateToFindaTution}
                  className={`nav-link ${
                    location === "/" && show === false ? "active" : ""
                  }
                    ${location === "/" ? "no-hand-icon" : ""}
                    `}
                >
                  Find a Tuition
                </Link>
              </Nav.Item>
              <Nav.Item className="ActiveBtn">
                <Button
                  variant="link"
                  onClick={handleShow}
                  className={`nav-link ${show === true ? "active" : ""}
                    ${location === "/search-result" ? "no-hand-icon" : ""}
                    `}
                >
                  Book a Demo
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/services"
                  className={`nav-link ${
                    location === "/services" && show === false ? "active" : ""
                  }      ${location === "/services" ? "no-hand-icon" : ""}
                    `}
                >
                  Rental
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  to="/teachers"
                  className={`nav-link ${
                    location === "/teachers" && show === false ? "active" : ""
                  } ${location === "/teachers" ? "no-hand-icon" : ""}`}
                >
                Teachers
                </Link>
                </Nav.Item>
              {/* <Nav.Item>
                <Link
                  to="/blogs"
                  className={`nav-link ${
                    location === "/blogs" && show === false ? "active" : ""
                  } ${location === "/blogs" ? "no-hand-icon" : ""}`}
                >
                  Blogs
                </Link>
              </Nav.Item> */}
                <Nav.Item>
                <Link
                  to="/about"
                  className={`nav-link ${
                    location === "/about" && show === false ? "active" : ""
                  } ${location === "/about" ? "no-hand-icon" : ""}`}
                >
                  About Us
                </Link>
              </Nav.Item>
            </Nav>
            
            <RightHeader>
            {!(selectLoginInfo?.data || email || teacherLoginInfo?.data) && (
              <Button type="submit" variant="primary" onClick={goToLoginPage}>
                Login
              </Button>
            )}
            {!(selectLoginInfo?.data || email || teacherLoginInfo?.data) && (
              <Button variant="secondary" onClick={goToRegisterPage}>
                Register
              </Button>
            )}
            {(selectLoginInfo?.data || email || teacherLoginInfo?.data) && (
              <AfterLogin>
                <UserImageWrap>
                  <img
                    src={
                      items?.is_profile == true
                        ? `${hostConfig.S3_BUCKET}${items?.profile_filename}?lastdate=` +
                          Date()
                        : UserImage
                    }
                    alt="User"
                  />
                </UserImageWrap>
                <UserInfo>
                  <UserName>
                    {`${items?.first_name} ${items?.last_name}`}
                  </UserName>
                  <UserCategory>{getUserType()}</UserCategory>
                </UserInfo>
                <Dropdown align="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="8.005"
                      viewBox="0 0 14 8.005"
                    >
                      <path
                        id="Icon_ionic-ios-arrow-down"
                        data-name="Icon ionic-ios-arrow-down"
                        d="M13.19,16.838l5.294-5.3a1,1,0,0,1,1.413,0,1.009,1.009,0,0,1,0,1.417l-6,6a1,1,0,0,1-1.38.029l-6.04-6.027a1,1,0,0,1,1.413-1.417Z"
                        transform="translate(-6.188 -11.246)"
                        fill="#940e34"
                      />
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/student-profile">
                      My Profile
                    </Dropdown.Item>
                    <Dropdown.Item href="/my-bookings">
                      My Classes
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="/"
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                      }}
                    >
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </AfterLogin>
            )}
          </RightHeader>
          </Navbar.Collapse>
          
        </Navbar>
      </HeaderMain>

      <BookADemoSearchPopup show={show} onHide={handleClose} />
      {showLoginRental && <LoginModal show
        title="Login to Manage Postings"
        onHide={() => setShowLoginRental(false)}
        onSubmit={() => {}}
      />}
    </>
  );
};

export default Header;
