import React from "react";
import { 
  SectionWrapper, 
  SectionHeading, 
  FeatureGrid, 
  FeatureCardWrapper, 
  IconWrapper, 
  IconImage, 
  Title, 
  Description 
} from '../../pages/home/homeStyle';
import Dashboard from '../../assets/images/dashboard_icon.svg';
import StudentData from '../../assets/images/student_data.svg';
import BatchManagement from '../../assets/images/batch_management.svg';
import TrustedView from '../../assets/images/trusted_view.svg';

const FeatureCard = ({ icon, title, description }) => (
  <FeatureCardWrapper>
    <IconWrapper>
      <IconImage src={icon} alt={title} />
    </IconWrapper>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </FeatureCardWrapper>
);

const FeatureSection = () => {
  const features = [
    {
      icon: Dashboard,
      title: "A visually appealing Dashboard",
      description:
        "The clean, modern interface ensures that key information is always at your fingertips, making managing your tuition center a seamless experience.",
    },
    {
      icon: StudentData,
      title: "Student Data Management",
      description:
        "Our student data management system offers a streamlined approach to handling student information. With a user-friendly interface, it allows for easy management of student's tracking of personal details.",
    },
    {
      icon: BatchManagement,
      title: "Batch Management",
      description:
        "Easily create and manage batches based on grade level, subject, or time slots. The system allows for efficient scheduling, and communicating with the students on real-time.",
    },
    {
      icon: TrustedView,
      title: "Trusted reviews from the enrolled students",
      description:
        "Our tuition management system includes a feature for trusted reviews from enrolled students, ensuring authentic feedback from those who have experienced the tuition services firsthand.",
    },
  ];

  return (
    <SectionWrapper>
      <SectionHeading>
        Effortless tuitions management - Simple, Efficient and Affordable!
      </SectionHeading>
      <FeatureGrid>
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </FeatureGrid>
    </SectionWrapper>
  );
};

export default FeatureSection;
