import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { RiFacebookFill, RiInstagramFill, RiTwitterFill, RiWhatsappLine } from 'react-icons/ri';
import {
  FooterContainer,
  FooterSlant,
  FooterContent,
  FooterSection,
  SectionTitle,
  LinksContainer,
  ContactContainer,
  ContactItem,
  FooterBottom,
  FooterWrapper,
  Copyright,
  SocialLinks
} from './footerStyle';

const Footer = () => {
  return (
    <FooterContainer>
      <FooterSlant />
      <FooterContent>
        <Container>
          <Row>
            <Col md={6}>
              <FooterSection>
                <SectionTitle>Legal</SectionTitle>
                <LinksContainer>
                  <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/refund-policy">Refund & Cancellation Policy</a>
                  <a href="/tc-for-student">T&Cs for Students</a>
                </LinksContainer>
              </FooterSection>
            </Col>
            <Col md={6}>
              <FooterSection>
                <SectionTitle>Contact Us</SectionTitle>
                <ContactContainer>
                  <ContactItem>
                    <strong>Office Address:</strong>
                    E 12, Ashiyana Nagar, Phase 2, Patna - 800025
                  </ContactItem>
                  <ContactItem>
                    <strong>Email ID:</strong>
                    <a href="mailto:ethosity.solutions@gmail.com">ethosity.solutions@gmail.com</a>
                  </ContactItem>
                  <ContactItem>
                    <strong>Contact:</strong>
                    +91 9822 507 475, +91 9665 987 151
                  </ContactItem>
                </ContactContainer>
              </FooterSection>
            </Col>
          </Row>
        </Container>
      </FooterContent>

      <FooterBottom>
        <Container>
          <FooterWrapper>
            <Copyright>
              Copyright 2025.EthosITy Solutions.| All Rights Reserved.
            </Copyright>
            <SocialLinks>
              <a href="https://www.facebook.com/tuitionmitra/" target="_blank" rel="noopener noreferrer">
                <RiFacebookFill />
              </a>
              <a href="https://www.instagram.com/tuitionmitra/" target="_blank" rel="noopener noreferrer">
                <RiInstagramFill />
              </a>
              <a href="#" rel="noopener noreferrer">
                <RiTwitterFill />
              </a>
              <a href="#" rel="noopener noreferrer">
                <RiWhatsappLine />
              </a>
            </SocialLinks>
          </FooterWrapper>
        </Container>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;