import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { Cookies } from "react-cookie";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Flex, Form, Input, Select, Button, Alert, message } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { getLocationList, getareaById } from "../../../api/list";
import { updateProfile } from "../../../api/update";
import { openInNewTab, overrideDefaults, nullifyEmptyValues, REGEX } from "../../../utils/helper";

import PageSpinner from "../../../components/PageSpinner";
import PageError from "../../../components/PageError";
import BaseModal from "../../../components/Modal/BaseModal";
import FileUploader from "../../../components/FileUploader/Input";

import UserImage from "../../../assets/images/user-placeholder-small.png";


enum FEE_UNIT {
  course = "course",
  week = "week",
  month = "month",
  quarter = "quarter",
  year = "year"
}
const feeUnitOptions = [
  { label: "per Course", value: FEE_UNIT.course },
  { label: "per Week", value: FEE_UNIT.week },
  { label: "per Month", value: FEE_UNIT.month },
  { label: "per Quarter", value: FEE_UNIT.quarter },
  { label: "per Year", value: FEE_UNIT.year },
];

const timeSlots = [
  { label: "6AM to 9AM", value: 0 },
  { label: "9AM to 1PM", value: 1 },
  { label: "2PM to 6PM", value: 2 },
  { label: "6PM to 9PM", value: 3 },
];

const S3_UPLOAD_BUCKET = "teacher_profile";

const initialValues = {
  profile_filename: "",
  tuition_name: "",
  description: "",
  city: "",
  area: "",
  address: "",
  location: "",
  email_id: "",
  mobile_number: "",
  website: "",
  fee_frequency: "",
  fee_per_month: "",
  time_slots: [],
  intro_video_url: "",
  intro_thumb_url: ""
};

const validationSchema = Yup.object().shape({
  profile_filename: Yup.string().matches(REGEX.url, "Must be a link URL"),
  tuition_name: Yup.string().required("Tuition Name is required"),
  description: Yup.string().required("Description is required"),
  city: Yup.string().required("City is required"),
  area: Yup.string().required("Area is required"),
  address: Yup.string().required("Address is required"),
  location: Yup.string().required("Map Location is required").matches(REGEX.url, "Must be a link URL"),
  email_id: Yup.string().required("Email ID is required").matches(REGEX.email, "Must be a valid Email ID"),
  mobile_number: Yup.string().required("Mobile Number is required").matches(REGEX.phone10d, "Must be a valid number in India"),
  website: Yup.string().matches(REGEX.url, "Must be a link URL"),
  fee_frequency: Yup.string().required("Unit is required"),
  fee_per_month: Yup.string().required("Average Fees is required").matches(REGEX.numbers, "Must be a number"),
  time_slots: Yup.array().of(Yup.number()).min(1, "Please select atleast one Time Slot"),
  intro_video_url: Yup.string().matches(REGEX.url, "Must be a link URL"),
  intro_thumb_url: Yup.string().matches(REGEX.url, "Must be a link URL"),
});

const ProfileForm: React.FC<any> = ({ onHide, onSuccess }) => {
  const cookies = new Cookies();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pageError, setPageError] = useState<string|null>(null);
  const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
  const [errorSave, setErrorSave] = useState<string|null>(null);

  const userInfo = useRef<any>(null);
  const cities = useRef<any[]>([]);
  const initialFormValues = useRef<any>(null);

  const fetchCities = async () => {
    const res = await getLocationList();
		if (!res.success) {
			setPageError("Failed to fetch Cities.");
			setIsLoading(false);
			return false;
		}
    const cityList = _.chain(res.records)
      .map((item: any) => ({ id: item.id, label: item.location, value: item.location }))
      .sortBy(["label"])
      .value();
		cities.current = cityList;
		return cityList;
  };

  const initialize = async () => {
		await fetchCities() &&
		setIsLoading(false);
	};

  useEffect(() => {
    const storedData = localStorage.getItem("loggedUsers");
		userInfo.current = storedData && JSON.parse(storedData);
    if (_.isEmpty(userInfo.current)) {
      setPageError("Unable to find user details");
      setIsLoading(false);
      return;
    }
    initialFormValues.current = overrideDefaults(initialValues, userInfo.current);
		initialize();
	}, []);

  const handleSubmit = async (values: any) => {
    setIsLoadingSave(true);

    const payload = nullifyEmptyValues(values);
    const res = await updateProfile(payload, userInfo.current.id);
    if (!res.success) {
      setErrorSave("Failed to update profile.");
      setIsLoadingSave(false);
      return;
    }
    message.success("Updated successfully.");
    onSuccess();
  };

  return (
    <BaseModal show onHide={onHide} title="Edit My Profile">
      {(isLoading && <PageSpinner />) ||
      (pageError && <PageError title={pageError} />) ||
      (
        <div className="tuition-profile-form-container">
          <Formik
            initialValues={initialFormValues.current}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              setFieldValue,
              handleBlur,
              handleSubmit
            }) => (
              <Form onFinish={handleSubmit}>
                <Row gutter={[20, 24]}>
                  <Col span={24}>
                    <Flex align="center" gap="large" style={{ marginBottom: 20 }}>
                      <div className="profile-image">
                        <img
                          src={values.profile_filename || UserImage}
                          alt="User"
                        />
                      </div>

                      <FileInput
                        name="profile_filename"
                        label="Tuition Logo"
                        fileTypes={["image/jpeg", "image/png"]}
                        maxFileSize={0.5}
                        value={values.profile_filename}
                        touched={touched.profile_filename}
                        error={errors.profile_filename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ flexGrow: 1 }}
                      />
                    </Flex>
                  </Col>

                  <Col span={24}>
                    <TextInput required
                      name="tuition_name"
                      label="Tuition Name"
                      placeholder="This will be visible on your tuition's profile page"
                      value={values.tuition_name}
                      touched={touched.tuition_name}
                      error={errors.tuition_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput required
                      name="description"
                      type="textarea"
                      label="Description"
                      placeholder="In one sentence, what sets your tuition apart?"
                      value={values.description}
                      touched={touched.description}
                      error={errors.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <SelectFiltered required
                      name="city"
                      label="Select City"
                      value={values.city}
                      touched={touched.city as boolean|undefined}
                      error={errors.city as string|undefined}
                      options={cities.current}
                      onSelect={(e) => {
                        setFieldValue("area", "");
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <SelectArea
                      city={values.city}
                      cityList={cities.current}
                      value={values.area}
                      touched={touched.area}
                      error={errors.area}
                      onSelect={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput required
                      name="address"
                      label="Address"
                      placeholder="Exact address with pincode"
                      value={values.address}
                      touched={touched.address}
                      error={errors.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput required
                      name="location"
                      label="Map Location"
                      placeholder="Link to tuition's location on Google Maps"
                      value={values.location}
                      touched={touched.location}
                      error={errors.location}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput required disabled
                      name="email_id"
                      label="Email ID"
                      placeholder="Specify tuition's email address"
                      value={values.email_id}
                      touched={touched.email_id}
                      error={errors.email_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput required disabled
                      name="mobile_number"
                      label="Mobile Number"
                      placeholder="Specify tuition's contact number"
                      value={values.mobile_number}
                      touched={touched.mobile_number}
                      error={errors.mobile_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <TextInput
                      name="website"
                      label="Website"
                      placeholder="Specify tuition's website"
                      value={values.website}
                      touched={touched.website}
                      error={errors.website}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <TextInput required
                      name="fee_per_month"
                      label="Average Fees"
                      value={values.fee_per_month}
                      touched={touched.fee_per_month}
                      error={errors.fee_per_month}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <SelectInput required
                      name="fee_frequency"
                      label="Select Unit"
                      options={feeUnitOptions}
                      value={values.fee_frequency}
                      touched={touched.fee_frequency}
                      error={errors.fee_frequency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    <MultiSelectInput required
                      name="time_slots"
                      label="Select Time Slots"
                      options={timeSlots}
                      value={values.time_slots}
                      touched={touched.time_slots}
                      error={errors.time_slots}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <FileInput
                      name="intro_video_url"
                      label="Intro Video"
                      fileTypes={["video/mp4"]}
                      maxFileSize={25}
                      value={values.intro_video_url}
                      touched={touched.intro_video_url}
                      error={errors.intro_video_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <FileInput
                      name="intro_thumb_url"
                      label="Intro Video Thumbnail"
                      fileTypes={["image/jpeg", "image/png"]}
                      maxFileSize={0.5}
                      value={values.intro_thumb_url}
                      touched={touched.intro_thumb_url}
                      error={errors.intro_thumb_url}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Col>

                  <Col span={24}>
                    {errorSave && <div className="error-alert"><Alert type="error" message={errorSave} /></div>}

                    <Button
                      block
                      className="submit-button"
                      type="primary"
                      shape="round"
                      size="large"
                      icon={<CheckCircleOutlined />}
                      htmlType="submit"
                      disabled={!values.tuition_name || !_.isEmpty(errors)}
                      loading={isLoadingSave}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </BaseModal>
  );
};

const TextInput: React.FC<any> = ({
  required, type, label, placeholder, name, value, touched, error, disabled, onChange, onBlur
}) => {
  const Element = type === "textarea" ? Input.TextArea : Input;
  const formError = error && touched ? error : undefined;

  return (
    <Form.Item
      validateStatus={formError && "error"}
      help={formError}
    >
      <div className="field-label">{label} {required ? "*" : ""}</div>
      <Element
        variant="filled"
        size="large"
        autoComplete="off"
        rows={6}
        disabled={disabled}
        style={{ width: "100%" }}
        placeholder={placeholder}
        name={name}
        value={value || ""}
        onChange={onChange}
        onBlur={onBlur}
      />
    </Form.Item>
  );
}

const SelectInput: React.FC<any> = ({
  required, options, label, name, value, touched, error, disabled, onChange, onBlur
}) => {
  const formError = error && touched ? error : undefined;

  const handleChange = (val: string) => onChange({ target: { name, value: val } });
  const handleBlur = (e: any) => onBlur({ target: { name } });

  return (
    <Form.Item
      validateStatus={formError && "error"}
      help={formError}
    >
      <div className="field-label">{label} {required ? "*" : ""}</div>
      <Select
        variant="filled"
        size="large"
        disabled={disabled}
        style={{ width: "100%" }}
        value={value || ""}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
}

const MultiSelectInput: React.FC<any> = ({
  required, options, label, name, value, touched, error, disabled, onChange, onBlur
}) => {
  const formError = error && touched ? error : undefined;

  const handleChange = (val: number[]) => onChange({ target: { name, value: val } });
  const handleBlur = (e: any) => onBlur({ target: { name } });

  return (
    <Form.Item
      validateStatus={formError && "error"}
      help={formError}
    >
      <div className="field-label">{label} {required ? "*" : ""}</div>
      <Select
        mode="multiple"
        allowClear
        size="large"
        disabled={disabled}
        style={{ width: "100%" }}
        value={value}
        options={options}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Form.Item>
  );
}

const FileInput: React.FC<any> = ({
  required, label, name, value, fileTypes, maxFileSize, touched, error, onChange, onBlur, style
}) => {
  const formError = error && touched ? error : undefined;

  return (
    <Form.Item
      style={style}
      validateStatus={formError && "error"}
      help={formError}
    >
      <div className="field-label">{label} {required ? "*" : ""}</div>
      <FileUploader
        bucket={S3_UPLOAD_BUCKET}
        variant="filled"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onView={(url) => openInNewTab(url)}
        fileTypes={fileTypes}
        maxFileSize={maxFileSize}
      />
    </Form.Item>
  );
}

type SelectFilteredProps = {
  required: boolean
  name: string
  label: string
  placeholder?: string
  value?: string
  touched?: boolean
  error: string | undefined
  options: {
    label?: string
    value: string
  }[]
  disabled?: boolean
  loading?: boolean
  onSelect: (event: any) => void
  onBlur: React.FocusEventHandler<HTMLElement>
}
const SelectFiltered: React.FC<SelectFilteredProps> = (
  { required, name, label, placeholder, value, touched, error, options, disabled, loading, onSelect, onBlur }
) => {
  const handleSelect = (value: string) => onSelect({ target: { name, value: value || "" } });
  const formError = error && touched ? error : undefined;

  return (
    <Form.Item
      validateStatus={formError && "error"}
      help={formError}
    >
      <div className="field-label">{label} {required ? "*" : ""}</div>
      <Select
        variant="filled"
        size="large"
        showSearch allowClear
        optionFilterProp="label"
        disabled={disabled}
        loading={loading}
        style={{ width: "100%" }}
        options={options}
        placeholder={placeholder}
        value={value || (_.isString(value) ? null : undefined)}
        onChange={handleSelect}
        onBlur={onBlur}
        filterOption={(inputValue, option) => {
          return option?.label?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }}
      />
    </Form.Item>
  );
};

const SelectArea: React.FC<any> = ({ city, cityList, value, onSelect, onBlur, touched, error }) => {
  const [areaList, setAreaList] = useState<any[]|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if(!city) {
      setAreaList(null);
      return;
    }
    const data = _.find(cityList, c => _.toLower(c.value) === _.toLower(city));
    if(!data) {
      setAreaList(null);
      return;
    }
    setIsLoading(true);
    getareaById(data.id).then((res) => {
      if (res?.records) {
        const areaData = _.chain(res.records)
          .map((item: any) => ({ label: item.area, value: item.area }))
          .sortBy(["label"])
          .value();
          setAreaList(areaData);
          setIsLoading(false);
      }
    });
  }, [city, cityList]);

  return (
    <SelectFiltered required
      name="area"
      label="Select Area"
      value={value}
      disabled={!areaList}
      loading={isLoading}
      touched={touched}
      error={error}
      options={areaList || []}
      onSelect={onSelect}
      onBlur={onBlur}
    />
  );
};

export default ProfileForm;