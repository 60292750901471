import styled from "styled-components";
import BannerBg from "../../assets/images/banner-pattern.png";
import DropArrow from "../../assets/images/arrow-down.svg";
import Checked from "../../assets/images/checked.svg";
import NextIcon from "../../assets/images/next-icon.svg";
import PrevIcon from "../../assets/images/prev-icon.svg";
import PrevIconOne from "../../assets/images/prev-icon-one.svg";
import PlusIcon from "../../assets/images/plus-lg.svg";
import MinusIcon from "../../assets/images/minus-icon.svg";

export const HomeBanner = styled.div`
  background-color: #940e34;
  background-image: url(${BannerBg});
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Montserrat", sans-serif;
  min-height: 740px;
  @media (max-width: 1440px) {
    min-height: 680px;
  }
  @media (max-width: 1366px) {
    min-height: 610px;
  }
`;
export const HomeBannerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  @media (max-width: 700px) {
    display: block !important;
    justify-content: center;
  }
  @media (max-width: 900px) {
    display: flex;
    align-items: center;
  }
  @media (max-width: 1366px) {
    padding-top: 35px;
  }
`;

export const HomeBannerLeft = styled.div`
  padding: 20px 0 0;
  max-width: 54%;
  flex: 54%;
  padding-bottom: 3rem;
  @media (max-width: 700px) {
    max-width: 100%;
    padding-bottom: 3rem;
  }
`;
export const HomeBannerTitle = styled.h1`
  color: #ffffff;
  font-size: 60px;
  line-height: 135%;
  font-weight: 800;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
  @media (max-width: 1440px) {
    font-size: 42px;
  }
  @media (max-width: 1366px) {
    font-size: 36px;
  }
  span {
    color: #940e34;
    font-size: 48px;
    line-height: 110%;
    display: inline-block;
    padding: 15px 40px;
    background: #ffffff;
    border-radius: 40px;
    box-shadow: inset 5px 3px 0px #dfdfdf, 6px 6px 0px #5e0720;
    transform: rotate(-12deg);
    vertical-align: 25px;
    @media (max-width: 1440px) {
      font-size: 32px;
    }
    @media (max-width: 1366px) {
      font-size: 28px;
      padding: 12px 28px;
    }
    @media (max-width: 1366px) {
      font-size: 28px;
      padding: 12px 28px;
    }
    @media (max-width: 357px) {
      margin-top: 20px;
    }
  }
  img {
    position: absolute;
    right: -60px;
    bottom: -45px;
    @media (max-width: 1440px) {
      max-width: 110px;
      right: -30px;
    }
    @media (max-width: 1366px) {
      max-width: 90px;
    }
    @media (max-width: 360px) {
      max-width: 70px;
      right: 0px;
      bottom: -25px;
    }
  }
`;
export const SearchBox = styled.div`
  box-shadow: 0px 0px 50px #0128341a;
  background: #ffffff;
  padding: 30px;
  border-radius: 20px;

  .ant-select {
    height: 58px;

    .ant-select-selector {
      border-radius: 16px;
      padding: 0 20px;
      font-size: 16px;

      .ant-select-selection-search {
        inset-inline-start: 20px;
        inset-inline-end: 40px;
      }
      
      .ant-select-selection-item {
        font-weight: 600;
      }
    }

    .ant-select-arrow, .ant-select-clear {
      inset-inline-end: 20px;
    }
  }

  .btn {
    width: 100%;
    font-weight: 600;
    &:disabled {
      background: #6c757d;
      border-color: #6c757d;
    }
  }
`;
// export const SearchBox = styled.div`
//   box-shadow: 0px 0px 50px #0128341a;
//   background: #ffffff;
//   padding: 30px;
//   border-radius: 20px;
//   .CloseBtn {
//     position: relative;
//     .resetbtn {
//       position: absolute;
//       top: 22px;
//       right: 10px;
//       height: 20px;
//       width: 20px;
//       color: #940e34;
//      // opacity: 0.6;
//      background: #ffffff;
//      @media (max-width: 1440px) {
//         top: 14px;
//       }
//     }
//   }
//   .ant-select {
//     height: auto;
//     font-family: inherit;
//   }
//   .dropdown {
//     .dropdown-toggle {
//       width: 100%;
//       text-align: left;
//       border-radius: 30px;
//       background: #f7f7f7;
//       padding: 20px;
//       font-size: 14px;
//       line-height: 130%;
//       font-weight: 400;
//       color: #012834;
//       border: 1px solid #f7f7f7;
//       @media (max-width: 1440px) {
//         padding: 14px 20px;
//       }
//       &::after {
//         position: absolute;
//         right: 20px;
//         top: 50%;
//         transform: translateY(-50%);
//         background: url(${DropArrow});
//         height: 8px;
//         width: 14px;
//         border: none;
//       }
//     }
//     .dropdown-menu {
//       width: 100%;
//       border-radius: 20px;
//       background: #f7f7f7;
//       padding: 15px 0;
//       border: none;
//       .dropdown-item {
//         padding: 8px 25px;
//       }
//     }
//   }
//   .btn-wrap {
//     margin: 20px 0 0 0;
//     text-align: center;
//     .btn {
//       width: 100%;
//       &:focus-visible {
//         background: #940e34;
//         color: #ffffff;
//         border-color: #940e34;
//         box-shadow: none;
//       }
//       &:active {
//         background: #940e34;
//         color: #ffffff;
//       }
//       & + .btn {
//         margin-left: 20px;
//       }
//     }
//   }
//   .ant-select-selector {
//     /* border-radius: 30px; */
//     background-color: #f7f7f7 !important;
//     font-size: 14px;
//     line-height: 130%;
//     font-weight: 400;
//     color: #012834;
//     border: 1px solid #f7f7f7 !important;
//     min-height: 60px;
//     padding: 5px 36px 5px 20px;
//     @media (max-width: 1440px) {
//       min-height: 48px;
//     }
//     .ant-select-selection-placeholder {
//       inset-inline-start: 20px;
//       inset-inline-end: 20px;
//       color: #707070;
//     }
//     .ant-select-selection-item {
//       background: #ffffff;
//       border: 1px solid #ffffff;
//       border-radius: 20px;
//       padding: 3px 5px;
//       height: 26px;
//       font-size: 14px;
//       line-height: 120%;
//       font-weight: 400;
//       color: #012834;
//       .anticon {
//         vertical-align: middle;
//         svg {
//           fill: #940e34;
//         }
//       }
//     }
//   }
// `;
export const HomeBannerRight = styled.div`
  max-width: calc(100% - 54%);
  flex: calc(100% - 50%);
  position: relative;
  @media (max-width: 700px) {
    display: none !important;
  }
  .carousel {
    padding-left: 140px;
    margin-right: -150px;
    padding-top: 60px;
    @media (max-width: 1680px) {
      padding-left: 70px;
      margin-right: -70px;
    }
    @media (max-width: 1440px) {
      padding-left: 30px;
      margin-right: -10px;
    }
    @media (max-width: 1366px) {
      padding-left: 20px;
    }
  }
`;
export const BannerTeacher = styled.div`
  position: relative;
  width: 135%;
  img {
    max-height: 620px;
    width: 120%;
    @media (max-width: 1440px) {
      max-height: 600px;
    }
    @media (max-width: 1366px) {
      max-height: 515px;
    }
    @media (max-width: 825px) {
      width: 70%;
    }
    @media (max-width: 990px) {
      margin-top:60px;
    }
  }
`;
export const TeacherMessage = styled.div`
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px 17px;
  width: 315px;
  position: absolute;
  right: 0;
  top: 20px;
  color: #012834;
  font-size: 20px;
  line-height: 140%;
  font-weight: 600;
  @media (max-width: 1440px) {
    font-size: 17px;
    width: 266px;
  }
  @media (min-width: 990px) {
    display: none;
  }
  @media (max-width: 1366px) {
    font-size: 15px;
    width: 240px;
  }
  @media (max-width: 825px) {
    width: 200px;
    font-size: 12px;
    right: 30px;
    top: 0px;
  }
  &::before {
    position: absolute;
    content: "";
    bottom: -24px;
    left: 30px;
    display: block;
    border-left: 0px solid transparent;
    border-right: 40px solid transparent;
    border-top: 25px solid #ffffff;
  }
  span {
    color: #940e34;
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 900;
    @media (max-width: 1440px) {
      font-size: 19px;
    }
    @media (max-width: 1366px) {
      font-size: 17px;
    }
    @media (max-width: 825px) {
      font-size: 14px;
    }
  }
`;
export const PopularSection = styled.div`
  padding: 100px 0 70px;
  @media (max-width: 1680px) {
    padding: 90px 0 60px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 50px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 45px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 40px;
  }
  .nav-tabs {
    max-width: 700px;
    border-radius: 0;
    margin: 0 auto 60px;
    border: none;
    @media (max-width: 1680px) {
      margin: 0 auto 50px;
    }
    @media (max-width: 1440px) {
      margin: 0 auto 40px;
    }
    @media (max-width: 1366px) {
      margin: 0 auto 30px;
    }
    @media (max-width: 1280px) {
      margin: 0 auto 30px;
    }
    .nav-item {
      background: #ffffff;
      width: calc(50% - 30px);
      margin: 0 12px;
      .nav-link {
        border: none;
        border-radius: 0;
        border-bottom: 2px solid rgba(112, 112, 112, 0.2);
        color: rgba(0,0,0,0.6);
        font-size: 24px;
        line-height: 100%;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
        padding: 26px;
        @media (max-width: 1440px) {
          font-size: 22px;
          line-height: 100%;
          padding: 20px;
        }
        @media (max-width: 1280px) {
          font-size: 20px;
          line-height: 120%;
        }
        @media (max-width: 700px) {
          font-size: 14px;
          padding: 15px;
        }
        &.active {
          color: #940e34;
          border-bottom: 2px solid #940e34;
          font-weight: bold;
        }
      }
    }
  }
`;

export const TutionGroup = styled.ul`
  padding: 0;
  margin: 0 -15px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  .tution-list {
    border-radius: 30px;
    flex: 0 0 25%; /* Adjust as needed */
    max-width: 25%; /* Adjust as needed */
    margin: 0 0 30px;
    padding: 0 15px;

    @media (max-width: 1400px) {
      margin: 0 0 10px;
      flex: 0 0 30%; /* Adjust as needed */
      max-width: 30%; /* Adjust as needed */
    }
    @media (max-width: 1199px) {
      margin: 0 0 10px;
      flex: 0 0 50%; /* Adjust as needed */
      max-width: 50%; /* Adjust as needed */
    }
    @media (max-width: 767px) {
      flex: 0 0 100%; /* Full width on small screens */
      max-width: 100%;
    }
  }
`;

export const TutionListWrap = styled.div`
  padding: 40px 20px;
  border-radius: 30px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 210px;
  @media (max-width: 1400px) {
    margin: 20px 0 0 0;
  }
`;
export const TutionImage = styled.div``;
export const TutionTitle = styled.h4`
  color: #012834;
  font-size: 20px;
  line-height: 120%;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  margin: 26px 0 0 0;
  text-align: center;
  @media (max-width: 1400px) {
    font-size: 18px;
    line-height: 120%;
  }
`;
export const ReviewSection = styled.div`
  background: #f0f0f0;
  padding: 100px 0;
  font-family: "Montserrat", sans-serif;
  @media (max-width: 1440px) {
    padding: 80px 0;
  }
  @media (max-width: 991px) {
    padding: 60px 0;
  }
  .slick-slider {
    margin: 0 -15px;
    .slick-track {
      display: flex;
      .slick-slide {
        height: inherit;
        & > div {
          height: 100%;
        }
      }
    }
    .slick-arrow {
      position: absolute;
      top: -80px;
      width: 45px;
      height: 30px;
      @media (max-width: 1680px) {
        top: -70px;
      }
      @media (max-width: 1440px) {
        top: -60px;
      }
      @media (max-width: 1366px) {
        top: -50px;
      }
      @media (max-width: 1199px) {
        top: -40px;
      }
      &::before {
        content: "";
        background-size: cover;
        opacity: 1;
        display: block;
        width: 45px;
        height: 30px;
        @media (max-width: 1199px) {
          width: 30px;
          height: 20px;
        }
      }

      &.slick-next {
        right: 15px;
        @media (max-width: 1440px) {
          right: 20px;
        }
        @media (max-width: 1366px) {
          right: 20px;
        }
        @media (max-width: 1199px) {
          right: 10px;
        }
        &.slick-disabled {
          &::before {
            background-image: url(${PrevIconOne});
            transform: rotate(180deg);
          }
        }
        &::before {
          background-image: url(${NextIcon});
        }
      }

      &.slick-prev {
        right: 90px;
        left: auto;
        @media (max-width: 1366px) {
          right: 100px;
        }
        @media (max-width: 1199px) {
          right: 70px;
        }
        &.slick-disabled {
          &::before {
            background-image: url(${PrevIconOne});
          }
        }
        &::before {
          background-image: url(${PrevIcon});
        }
      }
    }
  }
`;
export const ReviewTitle = styled.h2`
  color: #012834;
  font-size: 42px;
  line-height: 130%;
  font-weight: 800;
  margin-bottom: 55px;
  @media (max-width: 1680px) {
    font-size: 40px;
    margin-bottom: 50px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-bottom: 40px;
  }
  @media (max-width: 1366px) {
    font-size: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 1199px) {
    font-size: 24px;
    margin-bottom: 25px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
`;

export const ReviewCard = styled.div`
  min-width: 400px;
`;
export const StudentsData = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StudentsCard = styled.div`
  padding: 0 15px;
  height: 100%;
`;
export const StudentsName = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 1680px) {
    margin-bottom: 18px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 15px;
  }
  @media (max-width: 1366px) {
    margin-bottom: 10px;
  }
`;
export const StudentsImg = styled.div`
  flex: 0 0 60px;
  max-width: 60px;
  height: 60px;
  width: 60px;
  img {
    border-radius: 30px;
    box-shadow: 0px 10px 10px #7070700d;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
export const StudentsReview = styled.div`
  flex-grow: 1;
  margin-left: 20px;
  h4 {
    font-size: 20px;
    line-height: 24px;
    color: #940e34;
    font-weight: 700;
    margin-bottom: 0px;
    @media (max-width: 1440px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: 1280px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  h6 {
    font-size: 12px;
    line-height: 24px;
    color: #012834;
    opacity: 0.5;
    margin-bottom: 0px;
  }
`;
export const StudentsDetails = styled.div`
  font-size: 16px;
  line-height: 30px;
  color: #012834;
  font-weight: 400;
  margin-bottom: 80px;
  opacity: 0.8;
  @media (max-width: 1680px) {
  }
  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 22px;
  }
`;
export const StudentsStar = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 30px;
  span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: #012834;
    opacity: 0.5;
    margin-left: 5px;
  }
  ul {
    display: flex;
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0;
    li {
      padding-right: 10px;
      img {
        height: 22px;
        width: auto;
        margin-top: 15px;
        opacity: 0.4;
        @media (max-width: 1440px) {
          height: 20px;
          width: auto;
          margin-top: 10px;
        }
      }
      &.active {
        img {
          opacity: 1;
        }
      }
    }
  }
`;
export const StudentsCardWrap = styled.div`
  background-color: #ffffff;
  padding: 30px;
  border-radius: 30px;
  height: 100%;
`;
export const FaqSection = styled.div`
  padding: 100px 0px 158px;
  @media (max-width: 1680px) {
    padding: 90px 0 140px;
  }
  @media (max-width: 1440px) {
    padding: 70px 0 120px;
  }
  @media (max-width: 1366px) {
    padding: 60px 0 90px;
  }
  @media (max-width: 1280px) {
    padding: 50px 0 70px;
  }
`;
export const FaqTitle = styled.h2`
  color: #012834;
  font-size: 42px;
  line-height: 50px;
  font-weight: 800;
  margin-bottom: 30px;
  @media (max-width: 1680px) {
    font-size: 40px;
    margin-bottom: 25px;
  }
  @media (max-width: 1440px) {
    font-size: 36px;
    margin-bottom: 15px;
  }
  @media (max-width: 1366px) {
    font-size: 32px;
    margin-bottom: 10px;
  }
  @media (max-width: 1280px) {
    font-size: 30px;
  }
`;
export const FaqContent = styled.div`
  color: #000000;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  margin-bottom: 60px;
  @media (max-width: 1680px) {
    margin-bottom: 50px;
  }
  @media (max-width: 1440px) {
    margin-bottom: 40px;
  }
  @media (max-width: 1366px) {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 30px;
  }
`;
export const FaqImg = styled.div`
  padding-left: 85px;
  @media (max-width: 1680px) {
    padding-left: 75px;
  }
  @media (max-width: 1440px) {
    padding-left: 60px;
  }
  @media (max-width: 1366px) {
    padding-left: 50px;
  }
  @media (max-width: 1366px) {
    padding-left: 40px;
  }
  img {
    max-width: 83.1%;
  }
`;
export const FaqAccordion = styled.div`
  padding-left: 10px;
  @media (max-width: 1440px) {
    padding-left: 0px;
  }
  .accordion-item {
    border: 1px solid transparent;
    margin-top: 20px;
    @media (max-width: 1440px) {
      margin-top: 12px;
    }
    @media (max-width: 1366px) {
      margin-top: 8px;
    }
    .accordion-header {
      .accordion-button {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        text-align: left;
        letter-spacing: 0px;
        color: #012834;
        background-color: #f7f7f7;
        box-shadow: none;
        border-radius: 63px;
        padding: 28px 70px 28px 30px;
        position: relative;
        @media (max-width: 1440px) {
          font-size: 18px;
          line-height: 22px;
          border-radius: 50px;
          padding: 20px 70px 20px 20px;
        }
        @media (max-width: 1440px) {
          font-size: 16px;
          line-height: 22px;
          border-radius: 40px;
          padding: 20px 70px 20px 20px;
        }
        &:not(.collapsed)::after {
          background: url(${MinusIcon});
          background-repeat: no-repeat;
          right: 20px;
          transform-origin: center;
          transition: all 0.5s ease-in-out;
          transform: rotate(0deg);
          @media (max-width: 1440px) {
            right: 20px;
          }
        }
        &::after {
          content: "";
          position: absolute;
          margin-left: auto;
          background: url(${PlusIcon});
          background-repeat: no-repeat;
          border-radius: 50%;
          right: 28px;
          transform-origin: center;
          transition: all 0.5s ease-in-out;
          transform: rotate(90deg);
          @media (max-width: 1440px) {
            right: 23px;
          }
        }
        &::before {
          content: "";
          position: absolute;
          background-color: #ffffff;
          border-radius: 50%;
          height: 60px;
          width: 60px;
          right: 10px;
          box-shadow: 0px 3px 20px #0000000f;
          opacity: 1;
          @media (max-width: 1440px) {
            height: 50px;
            width: 50px;
            right: 10px;
          }
        }
      }
    }
    .accordion-body {
      font-size: 16px;
      line-height: 30px;
      color: #012834;
      background-color: #f7f7f7;
      box-shadow: none;
      border-radius: 30px;
      padding: 30px;
      margin-top: 10px;
      opacity: 1;
      @media (max-width: 1440px) {
        font-size: 14px;
        line-height: 26px;
        border-radius: 20px;
        padding: 20px;
        margin-top: 5px;
      }
    }
  }
`;

//Feature section css
export const SectionWrapper = styled.div`
  padding: 2rem 1rem;
  margin: 0 auto;
`;

export const SectionHeading = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  color: #940e34;
  margin-bottom: 2rem;
  font-weight: 800;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 576px) {
    font-size: 1.5rem;
  }
`;

export const FeatureGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }
`;

export const FeatureCardWrapper = styled.div`
  flex: 1 1 calc(25% - 1rem); /* Four cards per row */
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 200px;
  max-width: 350px;

  @media (max-width: 1024px) {
    flex: 1 1 calc(25% - 1rem);
  }

  @media (max-width: 991px) {
    flex: 1 1 calc(33.33% - 1rem); 
  }

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 1rem); 
  }

  @media (max-width: 576px) {
    flex: 1 1 100%; 
  }
`;

export const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: #940e34;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 1rem 0;
`;

export const IconImage = styled.img`
  width: 30px;
  height: 30px;
`;

export const Title = styled.h3`
  font-size: 1.2rem; 
  color: #940e34;
  margin-bottom: 1rem;
  font-weight: 600;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
`;

export const Description = styled.p`
  font-size: 1rem; 
  color: #00000099;
  line-height: 1.5;
  font-weight: 500;

  @media (max-width: 576px) {
    font-size: 0.95rem;
    font-weight: 500;
  }
`;

